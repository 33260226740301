
import setUrl from "~/mixins/setUrl.js";
export default {
  data: () => ({
    mixins: [setUrl],
    loading: false,
    data: [],
    page: 1,
    limit: 10,
    search: "",
    keyword: "",
    noResult: false,
    searchResult: "",
  }),
  created() {
    this.search = this.$route.query.keyword ? this.$route.query.keyword : "";
    this.searchResult = this.$route.query.keyword
      ? this.$route.query.keyword
      : "";
  },
  mounted() {
    this.search = this.$route.query.keyword ? this.$route.query.keyword : "";
    this.searchResult = this.$route.query.keyword
      ? this.$route.query.keyword
      : "";
  },
  async fetch() {
    await this.fetchCommunity();
  },
  methods: {
    onSubmit() {
      this.data = [];
      this.$router.push({ query: { keyword: this.search } });
      this.page = 1;
      this.searchResult = this.search;
      this.fetchCommunity();
    },
    loadMore() {
      this.page += 1;
      this.fetchCommunity();
    },
    async fetchCommunity() {
      this.loading = true;
      this.keyword = this.search;
      const params = `?name=${this.keyword}&sort=created_at&order=DESC&p=${this.page}&limit=${this.limit}`;
      await this.$store
        .dispatch("community/getAllCommunities", params)
        .then((res) => {
          this.noResult = res && res.length === 0;
          this.data.push(...res);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          throw new Error(err);
        });
    },
  },
};
