
import setUrl from '~/mixins/setUrl.js'
export default {
  layout: 'post',
  mixins: [setUrl],
  data: () => {
    return {
      loading: {
        submit: false,
        location: false
      },
      params_id: '',
      params_slug: '',
      detail: '',
      filelist: [],
      form: {
        content: ''
      },
      file: {
        image_path: ''
      },
      queryId: '',
      progressLists: [],
      tempImageUpload: []
    }
  },
  created () {
    this.checkParams()
  },
  methods: {
    onSuccessUpload (link) {
      this.tempImageUpload.push(link)
    },
    onProgress (list) {
      this.progressLists = list
    },
    checkParams () {
      if (this.$route.params) {
        this.params_id = this.$route.params.id
        this.params_slug = this.$route.params.slug
        this.fetchCommunityDetail()
      }
      if (this.$route.query && this.$route.query.id) {
        this.queryId = this.$route.query.id
        this.fetchQuestionById(this.queryId)
      }
    },
    async fetchQuestionById (id) {
      const params = {
        id: this.params_id,
        qid: id
      }
      await this.$store.dispatch('public/getDiscussionDetail', params)
        .then((res) => {
          this.form = {
            image_path: res.image_path,
            content: res.content
          }
        })
        .catch((err) => {
          throw new Error(err)
        })
    },
    async fetchCommunityDetail () {
      this.loading.detail = true
      const data = {
        param_id: this.params_id,
        param_slug: ''
      }
      await this.$store.dispatch('public/getCommunityDetail', data)
        .then((res) => {
          this.detail = res || null
          this.loading.detail = false
        })
        .catch((err) => {
          this.loading.detail = false
          throw new Error(err)
        })
    },
    async onChangeLoc (val) {
      this.loading.location = true
      await this.$store.dispatch('public/fetchLocationCommunity', val)
        .then((res) => {
          this.listLocation = res || []
          this.loading.location = false
        })
        .catch(() => {
          this.loading.location = false
        })
    },
    async submitForm () {
      this.loading.submit = true
      if (!this.form.content && !this.form.image_path) {
        this.$notify.info({
          message: 'Sepertinya kamu belum menulis...',
          offset: 50,
          duration: 3500
        })
      } else {
        const formData = new FormData()
        formData.append('id_community', this.params_id)
        formData.append('content', this.form.content)
        this.tempImageUpload.map((res) => {
          formData.append('image_path[]', res)
        })

        const url = this.queryId ? 'updateQuestion' : 'postSubmitQuestionCommunity'
        const payload = {
          formData,
          id: this.queryId
        }
        await this.$store.dispatch(`public/${url}`, payload)
          .then((res) => {
            this.resetForm()
            if (res.body) {
              this.$notify.success({
                message: 'Berhasil memulai diskusi',
                offset: 100,
                duration: 3500
              })
              setTimeout(() => {
                this.$router.push(this.localeLocation(`/community/${res.body.id_community}/${res.body.id}`)).catch(err => err)
              }, 1000)
              this.loading.submit = false
            }
          })
          .catch(() => {
            this.loading.submit = false
            this.$notify.error({
              title: 'Gagal menambah diskusi',
              offset: 100,
              message: 'Terjadi kesalahan server, silakan coba beberapa saat lagi'
            })
          })
      }
      this.loading.submit = false
    },
    resetForm () {
      this.form = {
        image_path: '',
        content: ''
      }
    },
    handlePhoto (val) {
      // this.file.image_path = val.file
      // this.form.image_path = val.thumb
    },
    deletePhoto () {
      this.$confirm('Apakah anda yakin ?', 'Hapus Photo', {
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: 'confrimPhoto',
        type: 'warning'
      }).then(() => {
        this.file.image_path = ''
        this.form.image_path = ''
        this.$message({
          type: 'success',
          message: 'Berhasil menghapus'
        })
      }).catch(() => {
      })
    },
    removePhoto (val) {
      console.log(val, 'remove')
      // this.file.image_path = val.file
      // this.form.image_path = val.thumb
    }
  }
}
