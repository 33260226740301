
export default {
  components: {
    imageUpload: () => import("~/components/organisms/image-upload"),
    userPostInfo: () => import("~/components/organisms/user-post-info"),
  },
  layout: "post",
  async asyncData({ store, env, params, query, app }) {
    let questionData = [];
    if (query.edit) {
      const { id } = params;
      const { edit } = query;
      questionData = await app.$getQuestion(id, edit);
    }
    return {
      questionData,
    };
  },
  data() {
    return {
      imageUploadList: [],
      defaultImage: [],
      content: "",
      isWithBroadcast: false,
      confirmWithBroadcast: false,
      isBroadcastDialogShow: false,
    };
  },
  mounted() {
    if (!this.$store.state.community.profile) {
      this.$router.back();
    }
    this.setDetailQuestion();
  },
  methods: {
    async setDetailQuestion() {
      if (this.$route.query.edit) {
        // set Default Value in edit condition

        this.content = this.questionData.content;
        if (this.questionData.community_content_media.length > 0) {
          this.defaultImage = this.$objectArrayParser(
            this.questionData.community_content_media,
            "url"
          );
        }
      }
    },
    handleInput(e) {
      if (e.target.value.length <= 4000) {
        this.content = e.target.value;
      }
      this.$forceUpdate();
    },
    handleBeforePost() {
      // validate
      const isContentFilled = this.content.length > 0;
      if (isContentFilled) {
        if (this.isWithBroadcast) {
          this.isBroadcastDialogShow = true;
        } else {
          this.handlePost(0);
        }
      } else {
        this.$notify.info({
          message: "Sepertinya kamu belum menulis...",
          offset: 50,
          duration: 3500,
        });
      }
    },
    async handlePost(isBroadcast = 0) {
      this.isBroadcastDialogShow = false;
      const data = {
        id_community: this.$store.state.auth.user.member.id_community,
        content: this.content,
        image_path: this.imageUploadList,
        is_broadcasted: isBroadcast,
      };
      // send data to server
      if (this.$route.query.edit) {
        await this.EditDicsussion(data);
      } else {
        await this.postDiscussion(data);
      }
    },
    handleUpload(val) {
      this.imageUploadList = val.files;
    },
    async EditDicsussion(discussionData) {
      await this.$editQuestion(this.$route.query.edit, discussionData)
        .then((response) => {
          this.$notify.success({
            message: "Diskusi berhasil diubah",
            offset: 50,
            duration: 3500,
          });
          const { id } = response;
          this.$router.push(
            "/community/" +
              this.$store.state.auth.user.member.id_community +
              "/" +
              id
          );
        })
        .catch((err) => {
          throw err;
        });
    },
    async postDiscussion(discussionData) {
      await this.$axios
        .post(process.env.BASE_API + "communities/questions", discussionData)
        .then((response) => {
          this.$notify.success({
            message: "Diskusi berhasil disimpan",
            offset: 50,
            duration: 3500,
          });
          const { id } = response.data.body;
          this.$router.push(
            "/community/" +
              this.$store.state.auth.user.member.id_community +
              "/" +
              id
          );
        })
        .catch((err) => {
          throw err;
        });
    },
  },
};
